import { Controller } from "react-hook-form";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { FormHelperText, Grid, TextField } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import styles from "./TemplateForm.module.scss"; // Import the CSS module

const theme = createTheme({
  palette: {
    primary: { main: "#510f5b" },
  },
});

const DatePickerComponent = ({
  name,
  control,
  label,
  className,
  defaultDate,
  helperText,
  rules,
}) => {
  // const initialDate = defaultDate ? moment(defaultDate) : null;

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Controller
          name={name}
          control={control}
          defaultValue={null} // Set default value formatted
          rules={rules}
          render={({
            field: { onChange, onBlur, value, ref },
            fieldState: { error },
          }) => {
            const formattedValue = value ? moment(value) : null;
            const isValidDate = formattedValue && formattedValue.isValid();

            return (
              <Grid>
                <MobileDatePicker
                  label={label}
                  format="DD/MM/YYYY"
                  value={isValidDate ? formattedValue : null} // Pass the valid moment object or null
                  className={styles.fullWidthDatePicker}
                  onChange={(date) => {
                    const formattedDate = date
                      ? moment(date).format("YYYY-MM-DD")
                      : null;
                    onChange(formattedDate); // Sending date in the "YYYY-MM-DD" format
                  }} // Format date on change
                  onBlur={onBlur}
                  textField={
                    <TextField
                      inputRef={ref}
                      color="secondary"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      error={!!error} // Set error state
                    />
                  }
                />
                {error && (
                  <FormHelperText error id={`error-text-${name}`}>
                    {helperText} {/* Display error message */}
                  </FormHelperText>
                )}
              </Grid>
            );
          }}
        />
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default DatePickerComponent;
