import { Checkbox, FormControlLabel, FormHelperText, Typography, Box } from "@mui/material";
import { useController } from "react-hook-form";

const CheckboxComponent = ({ name, control, label, options, rules, helperText }) => {
  const {
    field: { onChange, value = false }, // Default value to an empty array
    fieldState: { error },
  } = useController({ name, control, rules });

  return (
    <>
      <Box mt={2} display="flex" alignItems="end">
        <FormControlLabel control={<Checkbox color="secondary" onChange={(e) => onChange(e.target.checked)} checked={value} />} />
        <Typography mb={1} variant="subtitle1">
          {label}
        </Typography>

        {error && <FormHelperText error>{helperText}</FormHelperText>}
      </Box>
    </>
  );
};

export default CheckboxComponent;
