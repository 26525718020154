import { initializeCartItems } from "../redux/slices/cartSlice";

// Generic function to load state from localStorage by key
export const loadStateFromStorage = (key) => {
  const savedState = localStorage.getItem(key);
  return savedState ? JSON.parse(savedState) : null;
};

// Generic function to save state to localStorage by key
export const saveStateToStorage = (key, state) => {
  localStorage.setItem(key, JSON.stringify(state));
};

// Generic function to subscribe to localStorage changes and dispatch updates to Redux store
export const subscribeToLocalStorageChanges = (store) => {
  const syncStateFromLocalStorage = (event) => {
    const key = event.key;

    if (key) {
      const newState = JSON.parse(event.newValue);

      // If key corresponds to cartItems or userState, dispatch the corresponding action
      if (key === "cartItems") {
        store.dispatch(initializeCartItems(newState));
      }
    }
  };

  window.addEventListener("storage", syncStateFromLocalStorage);

  // Clean up listener on store destruction
  return () => {
    window.removeEventListener("storage", syncStateFromLocalStorage);
  };
};
