export const loginFields = [
  {
    name: "username",
    label: "fields.username",
    validation: {
      required: "validation.username.required",
      pattern: {
        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        message: "validation.username.pattern",
      },
    },
    type: "email",
  },
  {
    name: "password",
    label: "fields.password",
    validation: {
      required: "validation.password.required",
    },
    type: "password",
  },
];
