import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: [],
  isCartDrawerOpen: false,
  selectedToy: null,
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setSelectedToy(state, action) {
      state.selectedToy = action.payload;
    },
    clearSelectedToy(state) {
      state.selectedToy = null;
    },
    addItem(state, action) {
      const { toyId: id, name, yourPrice: price, images } = action.payload;
      const newItem = {
        id: id,
        name: name,
        price: price,
        image: images[0],
      };

      const existingItem = state.items.find((item) => item.id === newItem.id);
      if (!existingItem) {
        state.items.push({ ...newItem });
      }
    },

    // Remove an item from the cart
    deleteItem(state, action) {
      state.items = state.items.filter((item) => item.id !== action.payload);
    },

    // Clear the cart
    clearCart(state) {
      state.items = [];
    },

    toggleCartDrawer(state, action) {
      state.isCartDrawerOpen = action.payload;
    },

    initializeCartItems(state, action) {
      state.items = action.payload;
    },
  },
});

export const selectCartItems = (state) => state.cart.items;
export const selectTotalPrice = (state) => {
  const total = state.cart.items.reduce((sum, item) => sum + item.price, 0);
  return parseFloat(total.toFixed(2));
};

export const { addItem, deleteItem, toggleItem, clearCart, toggleCartDrawer, setSelectedToy, clearSelectedToy, initializeCartItems } = cartSlice.actions;
export const selectIsCartDrawerOpen = (state) => state.cart.isCartDrawerOpen;
export const getSelectedToy = (state) => state.cart.selectedToy;

export default cartSlice.reducer;
