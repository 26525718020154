import { createContext, useContext, useState, useEffect } from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(() => localStorage.getItem("token") || null);
  const [isAuthenticated, setIsAuthenticated] = useState(!!token);
  const [user, setUser] = useState(null);

  const login = (newToken, user) => {
    if (!newToken || !user) {
      return;
    }
    localStorage.setItem("token", newToken);
    localStorage.setItem("userName", user.displayName);

    setToken(newToken);
    setIsAuthenticated(true);
    setUser(user);
  };

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userName");

    setToken(null);
    setIsAuthenticated(false);
  };

  useEffect(() => {
    setIsAuthenticated(!!token);
  }, [token]);

  return <AuthContext.Provider value={{ token, isAuthenticated, login, logout, user }}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
