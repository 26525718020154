import { lazy, Suspense } from "react";
import ProgressSpinner from "./ProgressSpinner";

const WithLazy = (importFunc) => {
  const LazyComponent = lazy(importFunc);

  return (props) => (
    <Suspense fallback={<ProgressSpinner />}>
      <LazyComponent {...props} />
    </Suspense>
  );
};

export default WithLazy;
