import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../utils/apiConstants";

export const saveChildren = createAsyncThunk(
  "user/saveChildren",
  async (childrenData, { rejectWithValue, getState }) => {
    try {
      const { id: userId, token } = getState().user.user;
      console.log("Saving children:", childrenData); // Debug the children data

      if (!token) {
        throw new Error("No token available");
      }
      //   const userId = "vMI8B2bQNxsodUMpnKeanf3sarNH";
      //   const token =
      //     "eyJhbGciOiJub25lIiwidHlwIjoiSldUIn0.eyJuYW1lIjoiUHVybmltYSBCaXJ0aGFyZSIsImVtYWlsIjoicHVybmltYTg4LmJpcnRoYXJlQGdtYWlsLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiYXV0aF90aW1lIjoxNzMzMjE5MzcwLCJ1c2VyX2lkIjoidk1JOEIyYlFOeHNvZFVNcG5LZWFuZjNzYXJOSCIsImZpcmViYXNlIjp7ImlkZW50aXRpZXMiOnsiZW1haWwiOlsicHVybmltYTg4LmJpcnRoYXJlQGdtYWlsLmNvbSJdfSwic2lnbl9pbl9wcm92aWRlciI6ImN1c3RvbSJ9LCJpYXQiOjE3MzMyMTkzNzAsImV4cCI6MTczMzIyMjk3MCwiYXVkIjoiZGVtby1wcm9qZWN0IiwiaXNzIjoiaHR0cHM6Ly9zZWN1cmV0b2tlbi5nb29nbGUuY29tL2RlbW8tcHJvamVjdCIsInN1YiI6InZNSThCMmJRTnhzb2RVTXBuS2VhbmYzc2FyTkgifQ";

      const response = await axios.post(
        `${BASE_URL}/users/${userId}/children`,
        childrenData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error in saveChildren:", error);

      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

// User slice
const userSlice = createSlice({
  name: "user",
  initialState: {
    childrenData: [],
    loading: false,
    error: null,
    user: {
      id: "",
      name: "",
      token: "",
    },
  },
  reducers: {
    storeUser: (state, action) => {
      const { uid, displayName, accessToken } = action.payload;
      state.user = {
        ...state.user,
        id: uid,
        name: displayName || "",
        token: accessToken,
      };
    },
    logoutUser: (state) => {
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveChildren.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(saveChildren.fulfilled, (state, action) => {
        state.loading = false;
        state.childrenData = action.payload;
      })
      .addCase(saveChildren.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { storeUser, logoutUser } = userSlice.actions;

export default userSlice.reducer;
