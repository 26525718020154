import {
  Grid,
  TextField,
  FormHelperText,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useController } from "react-hook-form";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useState } from "react";

const TextFieldComponent = ({
  name,
  control,
  label,
  type = "text",
  sx,
  className,
  helperText,
  rules,
  ...rest
}) => {
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { error },
  } = useController({ name, control, rules });

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => setShowPassword((prev) => !prev);

  return (
    <Grid sx={sx}>
      <TextField
        color="secondary"
        label={label}
        type={type === "password" && !showPassword ? "password" : "text"}
        InputProps={{
          endAdornment: type === "password" && (
            <InputAdornment position="end">
              <IconButton
                onClick={togglePasswordVisibility}
                onMouseDown={(e) => e.preventDefault()}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
          onChange,
          onBlur,
          inputRef: ref,
          value: value || "",
          error: !!error,
        }}
        fullWidth
        margin="normal"
        error={!!error} // Set error prop
        className={className}
        {...rest}
      />
      {(error || helperText) && (
        <FormHelperText error={!!error}>{helperText}</FormHelperText>
      )}
    </Grid>
  );
};

export default TextFieldComponent;
