import { configureStore } from "@reduxjs/toolkit";
import cartReducer, { initializeCartItems } from "./slices/cartSlice";
import userReducer from "./slices/userSlice";
import registrationReducer from "./slices/registrationSlice";
import { loadStateFromStorage, saveStateToStorage, subscribeToLocalStorageChanges } from "../utils/storageUtils";

// Set up the Redux store
const store = configureStore({
  reducer: {
    cart: cartReducer,
    user: userReducer,
    registration: registrationReducer,
  },
});

// Load initial state from localStorage
const loadInitialState = () => {
  const cartItems = loadStateFromStorage("cartItems");

  if (cartItems) {
    store.dispatch(initializeCartItems(cartItems));
  }
};

loadInitialState();

// Subscribe to localStorage changes and update Redux state
subscribeToLocalStorageChanges(store);

// Save state slices to localStorage
store.subscribe(() => {
  const { items } = store.getState().cart;
  saveStateToStorage("cartItems", items);
});

export default store;
