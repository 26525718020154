import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  OutlinedInput,
  Chip,
  Box,
  Typography,
} from "@mui/material";
import { useController } from "react-hook-form";

const SelectComponent = ({
  name,
  control,
  label,
  options,
  getOptionLabel,
  getOptionValue,
  multiple,
  helperText, // Static helper text
  helpertextmap, // Dynamic helper text map
  sx,
  ...rest
}) => {
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { error },
  } = useController({ name, control, rules: rest.rules });

  // Ensure the selected option exists
  const selectedOption = options.find(
    (option) => getOptionValue(option) === value
  );

  // Check if helpertextmap is provided and get the dynamic helper text for the selected option
  const dynamicHelperText =
    helpertextmap && selectedOption
      ? helpertextmap[selectedOption.value]?.helperText || ""
      : "";
  const dynamicSubHelperText =
    helpertextmap && selectedOption
      ? helpertextmap[selectedOption.value]?.subHelperText || ""
      : "";

  // Handle change event
  const handleChange = (event) => {
    const selectedValue = event.target.value;
    onChange && onChange(event); // Call the onChange prop if provided
    onChange && onChange(selectedValue);
  };

  // Decide which helperText to show: static or dynamic
  const displayHelperText = dynamicHelperText || helperText;
  const displaySubHelperText = dynamicSubHelperText;

  return (
    <FormControl fullWidth margin="normal" sx={sx} error={!!error}>
      <InputLabel id={`select-label-${name}`} color="secondary">
        {label}
      </InputLabel>
      <Select
        multiple={multiple}
        onChange={handleChange}
        onBlur={onBlur}
        inputRef={ref}
        labelId={`select-label-${name}`}
        color="secondary"
        value={value || (multiple ? [] : "")} // Ensure default value is set correctly
        input={<OutlinedInput label={label} />}
        renderValue={(selected) => {
          if (multiple) {
            // Render selected values as Chips
            return (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((val) => {
                  const label = getOptionLabel(
                    options.find((option) => getOptionValue(option) === val)
                  );
                  return <Chip key={val} label={label} />;
                })}
              </Box>
            );
          } else {
            // For single select, display as regular text
            const label = getOptionLabel(
              options.find((option) => getOptionValue(option) === selected)
            );
            return label;
          }
        }}
      >
        {options.map((option) => (
          <MenuItem key={getOptionValue(option)} value={getOptionValue(option)}>
            {getOptionLabel(option)}
          </MenuItem>
        ))}
      </Select>

      {/* Conditionally render helperText and subHelperText using Typography */}
      {(displayHelperText || displaySubHelperText) && (
        <Box sx={{ mt: 1 }}>
          {displayHelperText && (
            <Typography
              mt={1}
              variant="body2"
              color={error ? "error" : "textSecondary"}
            >
              {displayHelperText}
            </Typography>
          )}
          {displaySubHelperText && (
            <Typography variant="caption" color="textSecondary">
              {displaySubHelperText}
            </Typography>
          )}
        </Box>
      )}
    </FormControl>
  );
};

export default SelectComponent;
