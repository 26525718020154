/**
 * This hook provides a way to authenticate a user with email and password
 * using Firebase Authentication and retrieve their JWT token.
 *
 * The result is a JWT token that can be used to authenticate the user in the client application.
 * via Bearer token in the Authorization header.
 *
 * It manages the authentication state, including loading, error, and token states.
 *
 * Usage:
 *
 * 1. Import the hook in your component:
 *    import useEmailPasswordAuth from "./useEmailPasswordAuth";
 *
 * 2. Use the hook in your component:
 *    const { token, error, loading, getToken } = useEmailPasswordAuth();
 *
 * 3. Call the getToken function with the user's email and password to authenticate:
 *    await getToken(email, password);
 *
 * 4. Access the token, error, and loading states as needed:
 *    - token: The JWT token if authentication is successful.
 *    - error: The error message if authentication fails.
 *    - loading: A boolean indicating whether the authentication request is in progress.
 */

import { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../config/firebase";
import { useLanguageContext } from "../context/LanguageContext";
import { LOGIN } from "../constants/NavbarConstants";

const useEmailPasswordAuth = () => {
  const [token, setToken] = useState(null);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);

  const { t } = useLanguageContext();
  const translations = t(LOGIN, { returnObjects: true });
  const { errorMsg } = translations;

  const errorMessages = {
    "auth/wrong-password": errorMsg.wrongPassword,
    "auth/user-not-found": errorMsg.userNotFound,
    "auth/too-many-requests": errorMsg.tooManyReq,
    "auth/network-request-failed": errorMsg.network,
    default: errorMsg.default,
  };

  const getToken = async (email, password) => {
    setLoading(true);
    setError(null);
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      const userToken = await user.getIdToken();
      setToken(userToken);
      setUser(user);
      return { userToken, user }; // Return token
    } catch (err) {
      const friendlyMessage = errorMessages[err.code] || errorMessages.default;
      setError(friendlyMessage);
    } finally {
      setLoading(false);
    }
  };

  return { token, error, loading, getToken, user };
};

export default useEmailPasswordAuth;
