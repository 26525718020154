import { Button } from "@mui/material";
import { styled } from "@mui/system";

// Define a styled version of Material-UI's Button component
const OutlinedButton = styled(Button)(({ customstyle }) => ({
  backgroundColor: "#510f5b",
  color: "#ffffff",
  padding: "6px 24px",
  cursor: "pointer",
  borderRadius: "25px", // Correct property name in JS is borderRadius
  "&:hover": {
    backgroundColor: "#510f5b",
  },
  ...customstyle, // Merge any additional custom styles
}));

export default OutlinedButton;
