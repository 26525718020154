import { Backdrop, CircularProgress } from "@mui/material";

const ProgressSpinner = ({ isLoading }) => {
  return (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
        display: isLoading ? "flex" : "none", // Show backdrop only when loading
      }}
      open={isLoading || false} // Show the backdrop when isLoading is true
    >
      <CircularProgress color="secondary" size={50} />
    </Backdrop>
  );
};

export default ProgressSpinner;
