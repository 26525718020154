import i18n from "i18next";
import { initReactI18next } from "react-i18next";

//Import all translation files
import English from "./locale/en/app.json";
import French from "./locale/fr/app.json";
import Norwegian from "./locale/nb/app.json";

const resources = {
  en: {
    translation: English,
  },
  fr: {
    translation: French,
  },
  nb: {
    translation: Norwegian,
  },
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: "en",
  returnObjects: true,
  interpolation: {
    escapeValue: false,
  },
  lng: "en", //default language
});

export default i18n;
